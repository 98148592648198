<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
      {{ text_snackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="showGoDialog"
      v-if="current_row !== null && current_row !== undefined"
      max-width="90%"
    >
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-card-text class="objective_content">
            <div class="title_contents">
              <div class="objective_content">
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title"
                      >{{ this.$t("navbar.assortment") + " ID" }}:
                    </span>
                    <span>{{ current_row.id }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" v-if="current_row?.brand">
                    <span class="title"
                      >{{
                        this.$t("brands.brand") + " " + this.$t("brands.name")
                      }}:
                    </span>
                    <span>{{ current_row.brand.name }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title"
                      >{{ this.$t("salesforce.status") }}:
                    </span>
                    <span>{{ current_row.status }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="4">
                    <span class="title"
                      >{{
                        this.$t("navbar.assortment") +
                          " " +
                          this.$t("brands.name")
                      }}:
                    </span>
                    <span>{{ current_row.name }}</span>
                  </v-col>
                  <v-col cols="12" sm="12" md="8" v-if="current_row?.operator">
                    <span class="title"
                      >{{
                        this.$t("navbar.Operators") +
                          " " +
                          this.$t("brands.name")
                      }}:
                    </span>
                    <span>{{ current_row.operator.name }}</span>
                  </v-col>
                </v-row>
              </div>
            </div>

            <v-row class="mt-2">
              <v-col cols="12" sm="12" md="12">
                <v-row class="mt-2 text-center">
                  <v-col cols="12" sm="12" md="12">
                    <span
                      style="font-size: 1rem; color: #000; font-weight: 500;"
                      >Product per Assortment:</span
                    >
                    <v-btn
                      v-if="importExcelProductTemplatePathName"
                      class="mb-2 ml-2"
                      color="blue darken-1"
                      @click="
                        downloadExcelTemplate(
                          importExcelProductTemplatePathName
                        )
                      "
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                      Plantilla Import
                    </v-btn>
                    <v-btn
                      color="success"
                      dark
                      class="mb-2 ml-2"
                      :loading="isSelectingIdproductperassortments"
                      @click="onUploadExcelIdproductperassortmentClick"
                    >
                      <b-icon
                        icon="file-excel-fill"
                        aria-hidden="true"
                      ></b-icon>
                      {{ buttonTextIdproductperassortments }}
                    </v-btn>
                    <input
                      ref="uploader_idproduct_per_assortments"
                      class="d-none"
                      type="file"
                      accept=".xls,.xlsx"
                      @change="onFileChangedIdproductperassortments"
                    />
                    <v-btn
                      color="warning"
                      dark
                      class="mb-2 ml-2"
                      @click="downloadExcelProductperAssortments"
                    >
                      <b-icon
                        icon="file-excel-fill"
                        aria-hidden="true"
                      ></b-icon>
                      Export
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="12" sm="12" md="12">
                    <veTableSubProducts
                      :row="this.assortmentProArr"
                      :assortmentDetail="this.current_row"
                      :update_table="update_table"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col cols="12" sm="12" md="12">
                <v-row class="mt-2 text-center">
                  <v-col cols="12" sm="12" md="12">
                    <span
                      style="font-size: 1rem; color: #000; font-weight: 500;"
                      >Chains per Assortment:</span
                    >
                    <v-btn
                      v-if="importExcelChainTemplatePathName"
                      class="mb-2 ml-2"
                      color="blue darken-1"
                      @click="
                        downloadExcelTemplate(importExcelChainTemplatePathName)
                      "
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                      Plantilla Import
                    </v-btn>
                    <v-btn
                      color="success"
                      dark
                      class="mb-2 ml-2"
                      :loading="isSelectingChainsPerAssortments"
                      @click="onUploadExcelChainsPerAssortmentClick"
                    >
                      <b-icon
                        icon="file-excel-fill"
                        aria-hidden="true"
                      ></b-icon>
                      {{ buttonTextChainsPerAssortments }}
                    </v-btn>
                    <input
                      ref="uploaderChainsPerAssortments"
                      class="d-none"
                      type="file"
                      accept=".xls,.xlsx"
                      @change="onFileChangedChainsPerAssortments"
                    />
                    <v-btn
                      color="warning"
                      dark
                      class="mb-2 ml-2"
                      @click="downloadExcelChainsPerAssortments"
                    >
                      <b-icon
                        icon="file-excel-fill"
                        aria-hidden="true"
                      ></b-icon>
                      Export
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-2">
                  <v-col cols="12" sm="12" md="12">
                    <veTableSubChains
                      :row="this.assortmentChainsArr"
                      :update_table="update_table"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click.stop="showGoDialog = false"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";
import download from "js-file-download";
import veTableSubProducts from "@/components/ve-table-subproducts";
import veTableSubMaterials from "@/components/ve-table-submaterials";
// import veTableSubPOS from "@/components/ve-table-subpos";
import veTableSubChains from "@/components/ve-table-subchains";

export default {
  name: "goDialogAssortment",
  props: [
    "value",
    "row",
    "reloadTable",
    "importExcelProductTemplatePathName",
    "importExcelChainTemplatePathName"
  ],
  components: {
    veTableSubProducts,
    veTableSubMaterials,
    // veTableSubPOS,
    veTableSubChains
  },
  data: function() {
    return {
      current_row: null,
      // assortmentPosArr: [],
      assortmentProArr: [],
      // assortmentMatArr: [],
      assortmentChainsArr: [],

      isProgressing: false,
      isUploadingExcelFile: false,
      selectedFile: null,
      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",

      isSelectingChainsPerAssortments: false,
      selectedFileIdposperassortments: null,
      defaultbuttonTextChainsPerAssortments: "Import",

      isSelectingIdproductperassortments: false,
      selectedFileIdproductperassortments: null,
      defaultButtonTextIdproductperassortments: "Import",

      /********************** Loading Spinner *******************/
      isLoadingSpinner: false
    };
  },
  watch: {
    async row(newVal, oldVal) {
      this.current_row = newVal;
      console.log("this.current_row - ", this.current_row);
      if (newVal) {
        await this.init(newVal);
      }
    }
  },
  computed: {
    showGoDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    buttonTextChainsPerAssortments() {
      return this.isSelectingChainsPerAssortments
        ? "Uploading..."
        : this.defaultbuttonTextChainsPerAssortments;
    },
    buttonTextIdproductperassortments() {
      return this.isSelectingIdproductperassortments
        ? "Uploading..."
        : this.defaultButtonTextIdproductperassortments;
    }
  },
  methods: {
    async downloadExcelTemplate(downloadFile) {
      let downloadfile = ApiService.getExcelTemplateFileUri(downloadFile);
      try {
        const response = await ApiService.get(downloadfile, {
          responseType: "arraybuffer"
        });
        download(response, downloadFile);
      } catch (error) {
        // this.close();
      }
    },
    async init() {
      // this.assortmentPosArr = this.current_row.assortment_pos;
      // if (this.assortmentPosArr.length > 0) {
      //   this.assortmentPosArr = this.assortmentPosArr.map((item) => {
      //     let push_item = { ...item };
      //     push_item.name = item.Pos.name;
      //     push_item.status = item.Pos.status;
      //     return push_item;
      //   });
      // }
      this.assortmentProArr = this.current_row.products;
      // this.assortmentMatArr = this.current_row.materials.map((el) => {
      //   let push_item = { ...el };
      //   push_item.assortment_material_status = el.assortment_material.status;
      //   return push_item;
      // });
      if (this.current_row.assortment_chains.length > 0) {
        this.assortmentChainsArr = this.current_row.assortment_chains.map(
          el => {
            let push_item = { ...el };
            push_item.chainName = "-";
            push_item.subChainName = "-";
            push_item.sub2ChainName = "-";
            if (el.ChainPerAssortment)
              push_item.chainName = el.ChainPerAssortment.name;
            if (el.subChainPerAssortment)
              push_item.subChainName = el.subChainPerAssortment.name;
            if (el.sub2ChainPerAssortment)
              push_item.sub2ChainName = el.sub2ChainPerAssortment.name;
            return push_item;
          }
        );
      } else {
        console.log("INIT CHAINS");
        this.assortmentChainsArr = [];
        const {
          isEmptyDefaultChainsOfBrand,
          isEmptyChainsPerAssortment
        } = await ApiService.get(
          `assortment/init_chains_per_assortment/${this.current_row.id}`
        );
        if (!isEmptyDefaultChainsOfBrand && !isEmptyChainsPerAssortment) {
          this.updatedRowData(this.current_row);
        }
      }
    },

    async downloadExcelProductperAssortments() {
      let fetch_url =
        "assortment/downloadexcel_idproductperassortments/" +
        this.current_row.id;
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "product_per_assortments.xlsx");
    },
    onUploadExcelIdproductperassortmentClick() {
      this.isSelectingIdproductperassortments = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingIdproductperassortments = false;
        },
        { once: false }
      );
      this.$refs.uploader_idproduct_per_assortments.click();
    },
    async onFileChangedIdproductperassortments(e) {
      this.selectedFileIdproductperassortments = e.target.files[0];
      if (
        window.confirm(
          `¿Está seguro de que desea cargar este archivo de Excel?`
        )
      ) {
        this.isSelectingIdproductperassortments = true;
        this.isLoadingSpinner = true;
        let data = new FormData();
        data.append("file", this.selectedFileIdproductperassortments);
        this.$refs.uploader_idproduct_per_assortments.value = "";
        try {
          const response = await ApiService.post(
            `assortment/uploadexcelIdproductperassortments/` +
              this.current_row.id,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelectingIdposperassortments = false;
          this.isLoadingSpinner = false;
          await this.reloadTable();
          await this.updatedRowData(this.current_row);
        } catch (error) {
          console.log(error);
          this.isSelectingIdposperassortments = false;
          this.isLoadingSpinner = false;
        }
      }
    },
    async downloadExcelChainsPerAssortments() {
      let fetch_url =
        "assortment/downloadexcel_chainsperassortments/" + this.current_row.id;
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "chains_per_assortment.xlsx");
    },
    onUploadExcelChainsPerAssortmentClick() {
      this.isSelectingChainsPerAssortments = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelectingChainsPerAssortments = false;
        },
        { once: false }
      );
      this.$refs.uploaderChainsPerAssortments.click();
    },
    async onFileChangedChainsPerAssortments(e) {
      this.selectedFileIdposperassortments = e.target.files[0];
      if (
        window.confirm(
          `¿Está seguro de que desea cargar este archivo de Excel?`
        )
      ) {
        this.isSelectingChainsPerAssortments = true;
        this.isLoadingSpinner = true;
        let data = new FormData();
        data.append("file", this.selectedFileIdposperassortments);
        this.$refs.uploaderChainsPerAssortments.value = "";
        try {
          const response = await ApiService.post(
            `assortment/uploadexcelChainsPerAssortments/` + this.current_row.id,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isLoadingSpinner = false;
          this.isSelectingChainsPerAssortments = false;
          await this.reloadTable();
          await this.updatedRowData(this.current_row);
        } catch (error) {
          console.log(error);
          this.isSelectingChainsPerAssortments = false;
          this.isLoadingSpinner = false;
        }
      }
    },
    async update_table() {
      await this.reloadTable();
      await this.updatedRowData(this.current_row);
    },
    async updatedRowData(row_data) {
      let fetch_url = "assortment/getitem/" + row_data.id;
      try {
        const resp = await ApiService.get(fetch_url);
        this.current_row = resp.data;
        this.init();
      } catch (error) {}
    }
  },
  async updated() {},
  async mounted() {}
};
</script>

<style>
.objective_content .row span {
  color: #000;
  font-size: 1rem;
}
.objective_content .row span.title {
  font-weight: bold;
  font-size: 1rem !important;
}
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.obj-title {
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: 500 !important;
  font-size: 1.25rem !important;
}
.title_contents {
  border: 3px solid #727272;
  border-radius: 5px;
}
.top_title h4 {
  margin: 0;
}
.top_title {
  padding: 15px 15px 15px 15px;
  border-bottom: 3px solid #727272;
}
.objective_content {
  padding: 15px;
}
</style>
