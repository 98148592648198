<template>
  <div>
    <Operators></Operators>
    <Assortments></Assortments>
  </div>
</template>

<script>
import Operators from "./Operators.vue";
import Assortments from "./Assortments.vue";

export default {
  name: "Assortment",
  components: {
    Operators,
    Assortments,
  },
};
</script>

<style></style>
